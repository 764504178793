@font-face {
  font-family: 'Gotham';
  src: url(/assets/font-family/Gotham/Gotham-Bold.otf) format('opentype');
  src: url(/assets/font-family/Gotham/Gotham-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Arca Majora 3';
  src: url(/assets/font-family/Arca-Majora-3/ArcaMajora3-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  src: url(/assets/font-family/Montserrat/Montserrat-Regular.ttf) format('truetype');
  src: url(/assets/font-family/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  src: url(/assets/font-family/Roboto/Roboto-Regular.ttf) format('truetype');
  src: url(/assets/font-family/Roboto/Roboto-Bold.ttf) format('truetype');
}

@mixin fontArcaMajora {
  font-family: 'Arca Majora 3', sans-serif;
}
@mixin fontMonserrat {
  font-family: 'Montserrat', sans-serif;
}
@mixin fontGotham {
  font-family: 'Gotham', sans-serif;
}
@mixin fontRoboto {
  font-family: 'Roboto', sans-serif;
}
