/*******
  root
 ******/

* {
  font: inherit;
}

::before,
::after {
  box-sizing: border-box;
  text-decoration: inherit;
  vertical-align: inherit;
}
/***********
  margins
  paddings
 **********/

body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
input,
nav,
select,
p,
textarea,
ul,
ol,
dl {
  margin: 0;
  padding: 0;
}

/*********
  hovers
 ********/

a,
button,
input[type='submit'] {
  &:hover {
    cursor: pointer;
  }
}

/**************
  decorations
 *************/
a,
input,
select,
textarea {
  background: none;
  color: inherit;
  border: none;
  text-transform: none;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
}

/**
* Remove the default vertical scrollbar in IE 10+.
*/
textarea {
  overflow: auto;
  font-weight: lighter !important;
}

/**
* 1. Add the correct box sizing in IE 10.
* 2. Remove the padding in IE 10.
*/

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
* Correct the cursor style of increment and decrement buttons in Chrome.
*/

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
* 1. Correct the odd appearance in Chrome and Safari.
* 2. Correct the outline style in Safari.
* 3. Remove the inner padding in Chrome and Safari on macOS.
*/

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  &::-webkit-search-decoration {
    -webkit-appearance: none; /* 3 */
  }
}

/**
* 1. Correct the inability to style clickable types in iOS and Safari.
* 2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
// MATERIAL OVERWRITE
// RIPPLE
.mat-form-field.mat-focused .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  opacity: 0 !important;
  transform: none !important;
  transition: none !important;
}
.mat-form-field-appearance-legacy .mat-form-field-ripple {
  top: 0;
  height: 0px;
  overflow: hidden;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #0000006b;
}
// MAT CHIPS
.mat-chip-list-wrapper input.mat-input-element,
.mat-chip-list-wrapper .mat-standard-chip {
  font-weight: normal;
  font-size: 1rem;
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 1 !important;
}

// MAT CAROUSEL REWRITE CURSOR DEFAULT STYLES
.carousel-slide:hover {
  cursor: inherit !important;
}
