// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Ng-Matero
@use '@ng-matero/extensions' as mtx;

// Plus imports for other components in your app.
@import 'normreset', 'mixins', '_charte';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-app-primary: mat.define-palette($primary);
$my-app-accent: mat.define-palette($accent, 500);

// The warn palette is optional (defaults to red).
$my-app-warn: mat.define-palette($warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($my-app-theme);

// Custom Theming for Ng-Matero (see https://github.com/ng-matero/extensions)
@include mtx.all-component-themes($my-app-theme);

/* GLOBAL STYLES */
a,
input,
select,
textarea {
  color: $wine;
}

a {
  &:hover {
    color: $gold;
  }
}

// HEAD TITLE
h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

h6 {
  @include h6;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.025rem;
  font-weight: 200;
  line-height: 1.5rem;
}

//FORMS

form {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
}
.mat-badge-content {
  background: rgb(186, 26, 26);
  color: white;
}
mat-radio-group {
  display: flex;
  justify-content: flex-start;
  padding: 30px 0px;
}

mat-radio-button {
  margin-left: 10px;
}

mat-form-field {
  padding: 10px 0px;
}

.mat-chip {
  &.mat-standard-chip {
    @include matChip;
  }
}

.selected {
  background-color: $wine;
}

mat-checkbox {
  margin-right: 15px;
}

// WRAPPERS
.wrapper {
  &-commercial {
    &--infos {
      display: grid;
      grid-auto-rows: auto;
      align-items: center;
      width: 60%;
      margin: 0 auto;
      grid-row-gap: 2vh;
      padding: 2vh;
    }
  }
}

.mat-step-header {
  pointer-events: none !important;
}

// SECTIONS
.section {
  &-contact {
    &-details {
      display: flex;
      justify-content: center;

      &--notes {
        display: flex;
        justify-content: center;
      }
    }
  }
}

// CONTAINERS
.container {
  &-list {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    &--notes,
    &--contacts {
      width: 60%;
    }

    &--map {
      width: 100%;
    }
  }

  &-results {
    &--contacts {
      flex-wrap: wrap;
      margin: 0 auto;
      color: $grey;
      text-align: right;
      margin: 2vh;
    }
  }

  &-type {
    &--a {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
      place-items: center;
    }
  }
}

// BLOCKS
.block {
  &-form {
    margin: 2rem 0;
  }

  &-snackbar {
    background-color: $almost-white !important;
    color: $wine !important;

    .mat-simple-snackbar-action {
      background-color: $wine !important;
      color: $almost-white !important;

      &:hover {
        background-color: $gold !important;
      }
    }
  }
}


// TEXT
.text {
  &-style {
    &--01 {
      @include text-gold;
    }

    &--02 {
      @include text-dark;
    }

    &--03 {
      @include text-grey;
    }

    &--04 {
      @include text-greyLight;
    }
  }
}

.style--info {
  color: $grey;
  margin: 0;
}


.close {
  @include buttonCircle;
}

// PROFILE TABS STYLES
.mat-tab-nav-bar,
.mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

// ICONS & CHIPS

.mat-icon {
  &-style {
    &--01 {
      justify-self: self-end;
      background-repeat: no-repeat;
      display: grid;
      fill: currentColor;
      width: 35px !important;
      height: 35px !important;
      grid-row: 1/3;
      border: 1px solid $wine;
      border-radius: 100px;
      background-color: #fff;
      padding: 5px;
    }
  }
}

.textarea-rgpd-icon-container {
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.rgdp-icon {
  display: inline-flex;
  align-content: center;
  height: 22px;
  margin: 5px;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  display: flex;
  align-items: center;
}

.rgdp-icon-group {
  display: flex;
  align-items: center;
}

h5 {
  color: $wine;
}

// GLOBAL STYLES

body {
  overflow: hidden;
  /* Hide scrollbars */
}

mat-error {
  @include fontMonserrat;
  display: flex;
  font-size: 10px;
  font-weight: 100;
  margin-top: 5px;
}

// CUSTOM SCROLLBAR
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: $light-gold;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: $light-grey;
}

.scrollbar {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  scrollbar-color: $gold $light-grey;
  scrollbar-width: thin !important;
}

// ng prime calendar styles
.p-button {
  background: $wine !important;
  border: $wine !important;
  color: white;
  width: 50px;
  border-radius: 0px 8px 8px 0px !important;
}

.p-calendar {
  height: 55px !important;
  margin-right: 20px;
  width: 100%;
}

.p-calendar span {
  width: 100%;
}

.p-inputtext {
  background-color: $gray-background !important;
  border-radius: 8px 0px 0px 8px !important;
}

.dates-group {
  display: flex;
  width: 100%;
  padding: 20px 0px 40px 0px;
  justify-content: space-between;
}

.start-date,
.end-date {
  width: 49%;
}

.chars-remaining {
  @include fontArcaMajora;
  width: 100%;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  margin-right: 5px;
}

.dropdownpanel-button-container {
  position: sticky;
  bottom: 0px;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.mat-table {
  background-color: $gray-background;
}

.mat-expansion-panel-header{
  background-color: white !important
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  align-items: center;
}

app-date-name-footer{
  max-height: 30px;
  max-height: 35px;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: $gray-background;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border: 2px solid #ff3373 !important;
}

p-calendar > .p-calendar > .p-inputtext {
  border-radius: 7px !important;
}

p-calendar:hover > .p-calendar:hover > .p-inputtext:hover {
  border: 2px solid black !important;
}


app-profile-header p-calendar > .p-calendar > .p-inputtext  {
  height: 35px;
}

app-profile-header .p-button.p-button-icon-only {
  height: 35px;
}

.legend-wrap {
  width: 50% !important;
  margin: auto;
}

.legend-title-text {
  display: none !important;
}


app-add-concurrent-dialog .mat-expansion-panel-content {
  background: $almost-white !important;
}
