$gold: #c9b99d; 
$pale-gold: rgba(201, 185, 157, 0.4);
$grey: #bcbcbc; 
$wine: #4d2c39; 
$light-grey: #e7e7e7; 
$light-gold: #e4d7c0; 
$almost-white: #f6f6f6;
$green: #71c68d; 
$gray-background: #fafafa; 
$clear-white: #ffffff;
$off-white: #f3f0f0;

$primary: (
  50: #f9f7f3,
  100: #efeae2,
  200: #e4dcce,
  300: #d9ceba,
  400: #d1c4ac,
  500: #c9b99d,
  600: #c3b295,
  700: #bcaa8b,
  800: #b5a281,
  900: #a9936f,
  A100: #ffffff,
  A200: #fffaf1,
  A400: #ffe6be,
  A700: #ffdca5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$accent: (
  50: #eae6e7,
  100: #cac0c4,
  200: #a6969c,
  300: #826b74,
  400: #684c57,
  500: #4d2c39,
  600: #462733,
  700: #3d212c,
  800: #341b24,
  900: #251017,
  A100: #ff6696,
  A200: #ff3373,
  A400: #ff0050,
  A700: #e60048,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$warn: (
  50: #ffe7ee,
  100: #ffc2d5,
  200: #ff99b9,
  300: #ff709d,
  400: #ff5288,
  500: #ff3373,
  600: #ff2e6b,
  700: #ff2760,
  800: #ff2056,
  900: #ff1443,
  A100: #ffffff,
  A200: #fffbfb,
  A400: #ffc8d1,
  A700: #ffaebc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
