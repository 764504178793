@import 'fonts', 'charte';
// MODALS & DIALOGS
@mixin modalTitle {
  @include fontRoboto;
  font-size: 1.45rem;
}
@mixin modalSubtitle {
  @include fontRoboto;
  color: $gold;
  font-size: 1rem;
}
@mixin modalLabel {
  @include fontGotham;
}
@mixin modalParagraph {
  @include fontRoboto;
  font-size: 1.025rem;
  font-weight: 200;
  line-height: 1.5rem;
  text-indent: 1rem;
}
@mixin styleFlag {
  @include fontRoboto;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  background: #4d2c39;
  color: #fff;
  border-radius: 100px;
  font-size: 0.825rem;
  display: inline-flex;
  font-weight: lighter;
  place-items: center;
}
@mixin styleFlagLined {
  @include fontRoboto;
  padding: 0.25rem 0.5rem;
  color: $wine;
  border-radius: 100px;
  border: solid 1.5px $wine;
  display: inline-flex;
  font-size: 70%;
  font-weight: normal;
  place-items: center;
}

@mixin styleFlagLinedSmaller {
  @include fontRoboto;

  font-family: "Roboto", sans-serif;
    padding: 0px 7px 0px 7px;
    color: $wine;
    border-radius: 15px;
    border: solid 1.5px $wine;
    display: inline-flex;
    font-size: 45%;
    font-weight: normal;
}

@mixin roundFlag {
  @include fontGotham;
  height: 2rem;
  width: 2rem;
  background: $wine;
  color: #fff;
  border-radius: 100px;
  display: flex;
  place-items: center;
  place-content: center;
}
@mixin profile-name {
  margin-top: auto 0;
  min-width: 50%;
  display: inline;
  height: 1rem;
  color: #000;
  font-family: 'Arca Majora 3', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

// TEXT
@mixin paragraph {
  font-size: 0.875rem;
  line-height: 1.375rem;
  width: 100%;
}
@mixin paragraphCenter {
  @include paragraph;
  text-align: center;
}
@mixin paragraphLeft {
  @include paragraph;
  text-align: left;
}

@mixin text-gold {
  @include fontMonserrat;
  color: $gold;
  font-weight: bold;
}
@mixin text-dark {
  @include fontMonserrat;
  color: #000;
  font-weight: bold;
}
@mixin text-grey {
  @include fontMonserrat;
  color: $grey;
  font-weight: bold;
}
@mixin text-greyLight {
  @include fontMonserrat;
  color: $light-grey;
  font-weight: bold;
}
@mixin thinWine {
  @include fontRoboto;
  color: $wine;
}
// BUTTONS
@mixin buttonCircle {
  background: $wine;
  border-radius: 100px;
  padding: 0.5rem;
  &:hover {
    background-color: $gold;
    color: $wine;
  }
  width: 30px;
  height: 30px;
}
@mixin mat-label-list {
  color: $gray-background;
  font-size: 30px;
  font-weight: 100;
}

@mixin buttonPrimary {
  align-items: center;
  background-color: $wine;
  color: #fff;
  display: flex;
  justify-content: center;
  transition: ease-in-out 0.15s;
  // &:hover {
  //   background-color: $gold;
  //   transition: ease-in-out 0.15s;
  // }
}

// HEAD TITLES
@mixin h1 {
  @include fontArcaMajora;
  color: $wine;
  font-size: 2rem;
  margin: 3rem 0;
}
@mixin h2 {
  @include fontArcaMajora;
  font-size: 1.5rem;
  font-weight: 200;
}
@mixin h3 {
  @include fontMonserrat;
  color: $grey;
  font-size: 1.17rem;
}
@mixin h4 {
  @include fontArcaMajora;
  color: $wine;
  font-size: 1.12em;
}
@mixin h5 {
  @include fontMonserrat;
  color: $grey;
  font-size: 0.83em;
}
@mixin h6 {
  @include fontMonserrat;
  color: $wine;
  font-size: 0.75em;
}

// DIALOGS

@mixin dialogTitle {
  display: flex;
  justify-content: space-between;
  font-family: 'Arca Majora 3', sans-serif;
}

@mixin profile-name {
  margin: auto 0;
  min-width: 50%;
  display: inline;
  height: 1rem;
  color: $wine;
  font-family: 'Arca Majora 3', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

// TITLES

@mixin title {
  font-family: 'Arca Majora 3', sans-serif;
  font-size: 20px;
  font-weight: 900;
}
@mixin accountTitle {
  font-family: 'Arca Majora 3', sans-serif;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: -0.36px;
}
@mixin accountTitle {
  font-family: 'Arca Majora 3', sans-serif;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: -0.36px;
}

// BUTTONS

@mixin mat-label-list {
  color: $gray-background;
  font-size: 30px;
  font-weight: 100;
}
// CHIPS
.chip {
  &--default {
    border: 2px solid;
    background: none;
    cursor: pointer;
    color: $wine;
  }
  &--selected {
    transition: ease-in-out 0.15s;
    background: $wine;
    color: #fff;
  }
}
@mixin matChip {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 100px;
  border: 2px solid $wine;
}

@mixin matChipForm {
  font-weight: lighter;
  padding: 5px 10px;
  border-radius: 100px;
  border: 2px solid $wine;
}

// MAT CARD
@mixin matCardHeader {
  color: $wine;
}

// INPUTS
@mixin inputButton {
  background-color: $wine;
  color: #fff;
  border-style: none;
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 1rem;
  &:hover {
    background-color: $gold;
  }
}

// SCROLLBAR
@mixin customScrollBar {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: $light-gold;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: $light-grey;
  }

  .scrollbar {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    scrollbar-color: $gold $light-grey;
    scrollbar-width: thin !important;
  }
}
